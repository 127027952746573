<template>
  <EdgeStack :id="esId" class="relative" header-class-name="mb-4 mt-8 px-4">
    <loading :active.sync="reqBusy" :is-full-page="false" />

    <template #header>
      <div class="flex justify-between">
        <div class="items-center panel-title header-title">
          {{ getTitleText }}
        </div>
      </div>
    </template>

    <template #footer>
      <t-button type="submit" @click="submit()" class="ml-3">Save</t-button>
    </template>

    <div class="px-6 pt-8">
      <PromoCard
        ref="promoCard"
        :title="getPromoTitle"
        :type="getPromoCategory"
        :code="getPromoCode"
        :percent="getPromoValue"
        :flat="getPromoValue"
      />

      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          @submit.prevent="handleSubmit(submitForm)"
          enctype="multipart/form-data"
        >
          <div class=" mt-8">
            <AppInput
              type="richselect"
              name="Promo Type"
              label="Promo Type"
              placeholder="Select a Promo type"
              :options="getPromoCategoryOptions"
              hide-search-box
              v-model="form.promotion_category"
              rules="required"
            />
          </div>
          <div class="grid grid-cols-2 gap-4 mt-2">
            <text-input
              v-model="form.title"
              rules="required"
              label="Promo Name"
              placeholder="e.g. Bijoy"
              :errorMessage="customError.title"
              errorKey="title"
              @clear-error="
                ($event) => {
                  customError[$event] = ''
                }
              "
            />
            <text-input
              :value="form.code.toUpperCase()"
              rules="required"
              label="Promo Code"
              placeholder="e.g. BIJOY16"
              :errorMessage="customError.code"
              errorKey="code"
              @input="
                ($event) => {
                  form.code = $event.toUpperCase()
                }
              "
              @clear-error="
                ($event) => {
                  customError[$event] = ''
                }
              "
            />
          </div>
          <div class="grid grid-cols-2 gap-4 mt-2">
            <text-input
              v-model="form.start_date"
              rules="required"
              label="Promo Start Date"
              type="date"
              :min="minStartDate"
            />
            <text-input
              v-model="form.end_date"
              rules="required"
              label="Promo Expire Date"
              type="date"
              :min="minEndDate"
            />
          </div>
          <div class="w-full mt-2">
            <text-input
              :value="getPromoValidityDuration"
              label="Usage validity"
              placeholder="days"
              disabled
            />
          </div>
          <div class="mt-2">
            <ValidationProvider
              name="Fleet Currency"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label text="Fleet Currency *" :error="errors[0]" />
              <TRichSelect
                v-model="fleetCurrencyProxy"
                :options="getFleetCurrencyOptions"
                :variant="{ danger: !!errors[0] }"
                placeholder="Select fleet currency"
              />
              <input-error-item :message="errors[0]" v-if="errors[0]" />
            </ValidationProvider>

            <div class="mt-1 text-xs text-gray-600">
              You have total
              <span class="font-bold">{{ fleetList.length || 0 }}</span> fleets
              with
              <span class="font-bold">
                {{ getFleetCurrencyOptions.length || 0 }}
              </span>
              unique
              {{
                getFleetCurrencyOptions.length > 1 ? 'currencies' : 'currency'
              }}. You can let your users use the promo code on single or
              multiple fleets of same currency.
            </div>
          </div>

          <div class="mt-4">
            <ValidationProvider
              name="Assigned Fleet"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" text="Assigned Fleet *" />
              <TRichSelect
                v-model="form.assigned_fleets"
                :options="getFleetOptions"
                :close-on-select="false"
                :variant="{ danger: !!errors[0] }"
                :placeholder="
                  fleetCurrencyProxy
                    ? 'Select fleet(s)'
                    : 'Select a fleet currency first'
                "
                multiple
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>

          <div class="mt-4">
            <ValidationProvider
              name="Target Rider Group"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" text="Target Rider Group *" />
              <TRichSelect
                v-model="form.eligible_point_categories"
                :options="targetGroupOptions"
                :close-on-select="false"
                :variant="{ danger: !!errors[0] }"
                placeholder="Select Target Group"
                multiple
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>

          <div
            class="grid grid-cols-2 gap-4 mt-2"
            v-if="getPromoCategory != 'FR'"
          >
            <template v-if="getPromoCategory == 'RD'">
              <text-input
                v-model="form.percentage"
                type="number"
                rules="required"
                label="Discount Parcentage (%)"
                placeholder="e.g. 15%"
                :errorMessage="customError.percentage"
                errorKey="percentage"
                subText="Enter valid positive number."
                :showSubText="true"
                @clear-error="
                  ($event) => {
                    customError[$event] = ''
                  }
                "
              />
            </template>

            <template v-else-if="getPromoCategory == 'FD'">
              <text-input
                v-model="form.flat_discount_amount"
                type="number"
                rules="required"
                label="Flat Discount Amount"
                placeholder="e.g. $20"
                :errorMessage="customError.flat_discount_amount"
                errorKey="flat_discount_amount"
                subText="Enter valid positive number."
                :showSubText="true"
                @clear-error="
                  ($event) => {
                    customError[$event] = ''
                  }
                "
              />
            </template>

            <text-input
              v-model.number="form.min_travel_time_in_sec"
              rules="required"
              label="Minimum Trip Time (In Mins)"
              placeholder="e.g. 30 mins"
              type="number"
              :errorMessage="customError.min_travel_time_in_sec"
              errorKey="min_travel_time_in_sec"
              subText="Enter valid positive number."
              :showSubText="true"
              @clear-error="
                ($event) => {
                  customError[$event] = ''
                }
              "
            />
          </div>

          <div class="grid grid-cols-2 gap-4 mt-2">
            <text-input
              rules="required"
              label="Max Number of Trips"
              placeholder="e.g. 10"
              v-model="form.num_of_trips"
              type="number"
              :errorMessage="customError.num_of_trips"
              errorKey="num_of_trips"
              subText="Enter valid positive number."
              :showSubText="true"
              @clear-error="
                ($event) => {
                  customError[$event] = ''
                }
              "
            />

            <text-input
              rules="required"
              label="Daily Used"
              placeholder="e.g. 2"
              v-model="form.daily_used"
              type="number"
              :errorMessage="customError.daily_used"
              errorKey="daily_used"
              subText="Enter valid positive number."
              :showSubText="true"
              @clear-error="
                ($event) => {
                  customError[$event] = ''
                }
              "
            />
          </div>

          <div class="w-full" v-if="getPromoCategory == 'RD'">
            <text-input
              v-model="form.max_amount"
              rules="required"
              label="Max Discounted Trip Cost"
              placeholder="e.g. $5"
              type="number"
              :errorMessage="customError.max_amount"
              errorKey="max_amount"
              subText="Enter valid positive number."
              :showSubText="true"
              @clear-error="
                ($event) => {
                  customError[$event] = ''
                }
              "
            />
          </div>
          <div class="mt-2">
            <input-label text="Status" />
            <t-rich-select
              placeholder="Select status"
              :options="statusOptions"
              valueAttribute="value"
              textAttribute="text"
              :hideSearchBox="true"
              v-model="form.is_active"
              class="w-full"
            />
          </div>

          <div class="mt-5">
            <ValidationProvider name="Promo description" v-slot="{ errors }">
              <input-label :error="errors[0]" text="Promo description" />
              <t-textarea rules="" v-model="form.description" />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>

          <button type="submit" ref="submitButton" class="hidden">Save</button>
        </form>
      </ValidationObserver>

      <!-- prop: {{ fleetCurrency }} proxy: {{ fleetCurrencyProxy }} form: {{ form.assigned_fleets }} -->
    </div>
  </EdgeStack>
</template>

<script>
import { PromoConfig } from '@/config/PromoConfig'
import { EventBus, getPromoCategoryName } from '@/utils'
import { deepCompareObjects } from '@/utils'
import { edgeStackEmitter } from '@/plugins/edgeStack'
import PromoCard from '@/components/cards/PromoCard.vue'
import TextInput from '@/components/form/TextInput'
import InputLabel from '@/components/form/InputLabel'
import EdgeStack from '@/components/modals/EdgeStack.vue'
import dayjs from 'dayjs'

export default {
  name: 'PromotionAddEdit',
  props: {
    esId: {
      type: [String, Number],
      required: false,
      default: 'promotionAddEdit',
    },
    primaryKey: {
      type: [String, Number],
      required: false,
      default: 0,
    },
    formData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
    currencySymbol: {
      type: [String, Number],
      required: false,
      default: '$',
    },
    fleetCurrency: {
      type: [String, Number],
      required: false,
      default: '',
    },
  },
  components: {
    EdgeStack,
    PromoCard,
    TextInput,
    InputLabel,
  },
  data() {
    return {
      reqBusy: false,
      form: {
        is_active: true,
        title: '',
        code: '',
        start_date: '',
        end_data: '',
        promotion_category: 'RD',
        percentage: '',
        min_travel_time_in_sec: '',
        // usage limit
        daily_used: '',
        // max trips
        num_of_trips: '',
        // max discount
        max_amount: '',
        flat_discount_amount: '',
        assigned_fleets: '',
        description: '',
        eligible_point_categories: [],
      },
      customError: {
        title: '',
        code: '',
        percentage: '',
        min_travel_time_in_sec: '',
        num_of_trips: '',
        daily_used: '',
        flat_discount_amount: '',
        max_amount: '',
      },
      minStartDate: '',
      minEndDate: '',
      // fleet list is the raw data from server
      fleetList: [],
      // based on fleet currency getFleetOptions() is computed
      fleetCurrencyProxy: this.fleetCurrency,

      statusOptions: [
        {
          value: false,
          text: 'Inactive',
        },
        {
          value: true,
          text: 'Active',
        },
      ],
      targetGroupOptions: [
        { text: 'Silver', value: 'Silver' },
        { text: 'Gold', value: 'Gold' },
        { text: 'Diamond', value: 'Diamond' },
        { text: 'Platinum', value: 'Platinum' },
        { text: 'All', value: 'All' },
      ],
    }
  },
  computed: {
    getTitleText() {
      return this.isEditing ? 'Edit Promo' : 'Add Promo'
    },
    getActionButtonText: function() {
      return this.isEditing ? 'Update' : 'Save'
    },
    getPromoTitle() {
      return 'Discount'
    },
    getPromoCode() {
      return this.form?.code || ''
    },
    getPromoCategory() {
      return this.form?.promotion_category
    },
    getPromoCategoryName() {
      return getPromoCategoryName(this.form.promotion_category) || ''
    },
    getPromoValidityDuration() {
      if (!this.form.start_date && !this.form.end_date) return '--'

      const end = this.$dayjs(this.form.end_date)
      const diff = end.diff(this.form.start_date, 'day')
      return `${diff} ${diff > 1 ? 'days' : 'day'}`
    },
    getPromoValue() {
      const amount = this.form?.flat_discount_amount || 0
      const percentage = this.form?.percentage || 0

      return this.getPromoCategory === 'RD'
        ? `${parseFloat(percentage).toFixed(0)}%`
        : this.getPromoCategory === 'FD'
        ? this.currencySymbol + parseFloat(amount).toFixed(2)
        : ''
    },
    getPromoCategoryOptions() {
      return [
        { text: 'Rate Discount', value: 'RD' },
        { text: 'Flat Discount', value: 'FD' },
        { text: 'Free Ride', value: 'FR' },
      ]
    },
    getFleetOptions() {
      const fleetListFiltered = this.fleetList.filter((fleet) => {
        return this.fleetCurrencyProxy === fleet.country.code3
      })

      return fleetListFiltered.map((fleet) => ({
        text: `${fleet.name} (${fleet.country.code3})`,
        value: fleet.id,
      }))
    },
    // get unique currencies from fleet list
    getFleetCurrencyOptions() {
      const currencyList = new Set()

      this.fleetList.forEach((fleet) => {
        currencyList.add(fleet.country.code3)
      })

      return Array.from(currencyList)
    },
  },
  watch: {
    'formData': {
      immediate: true,
      deep: true,
      handler(data) {
        if (Object.keys(data).length > 1) {
          this.form = { ...data }
        } else {
          this.resetForm()
          this.$edgeStack.shouldNotConfirm(this.esId)
        }
      },
    },
    // notify form is dirty & user should confirm before exiting
    'form': {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        if (
          deepCompareObjects(this.formData, updatedFormData) ||
          deepCompareObjects(updatedFormData, this.getFormModel())
        ) {
          return
        } else {
          this.$edgeStack.shouldConfirm(this.esId)
        }
      },
    },
    'form.eligible_point_categories'(newValue) {
      // eligible_point_categories value empty array means it's applied for all categories
      if (newValue.length === 0) {
        this.form.eligible_point_categories.push('All')
      }
      if (newValue.length > 1 && newValue.includes('All')) {
        const index = newValue.indexOf('All')
        if (index !== -1) {
          this.form.eligible_point_categories.splice(index, 1)
        }
      }
    },
    'fleetCurrency'(updated) {
      this.fleetCurrencyProxy = updated
    },
    // reset selected fleets on changing fleet currency
    'fleetCurrencyProxy'(updated) {
      if (this.isEditing && updated === this.fleetCurrency) return

      if (updated) this.form.assigned_fleets = []
    },
  },
  async created() {
    this.fleetList = await this.$http
      .get('/dashboard/fleets/?dropdown')
      .then((res) => res.data.data)
      .catch((err) => console.log('fleetListErr', err))
    this.minDatecalculation()
  },
  mounted() {
    edgeStackEmitter.on(
      this.$edgeStack.getEventName('close', this.esId),
      () => {
        this.$edgeStack.shouldNotConfirm(this.esId)
      }
    )
    EventBus.$on('fresh-sense', ($event) => {
      if ($event === '') {
        this.customError = {
          title: '',
          code: '',
          percentage: '',
          min_travel_time_in_sec: '',
          num_of_trips: '',
          daily_used: '',
          flat_discount_amount: '',
          max_amount: '',
        }
        this.fleetCurrencyProxy = []
      }
    })
  },
  methods: {
    minDatecalculation() {
      this.minStartDate = dayjs().format('YYYY-MM-DD')
      this.minEndDate = dayjs()
        .add(1, 'day')
        .format('YYYY-MM-DD')
    },
    onDirty() {
      this.$edgeStack.shouldConfirm(this.esId)
    },
    submit() {
      this.$refs.submitButton.click()
    },
    async submitForm() {
      const jsonstring_eligible_point_categories = JSON.stringify(
        this.form.eligible_point_categories.includes('All')
          ? []
          : this.form.eligible_point_categories
      )
      let formProxy = {
        ...this.form,
        eligible_point_categories: jsonstring_eligible_point_categories,
      }
      formProxy.start_date = formProxy.start_date + 'T00:00:00'
      formProxy.end_date = formProxy.end_date + 'T00:00:00'
      formProxy.description = formProxy.description || ''

      if (this.form.min_travel_time_in_sec) {
        formProxy.min_travel_time_in_sec =
          parseInt(this.form.min_travel_time_in_sec) * 60
      }

      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? PromoConfig.api.update(this.primaryKey)
        : PromoConfig.api.create

      const formData = new FormData()
      // todo: make a form data converter function
      for (const key in formProxy) {
        if (Array.isArray(formProxy[key])) {
          formProxy[key].forEach((el) => {
            formData.append(key, el)
          })
          continue
        }
        formData.append(key, formProxy[key])
      }

      const message = this.isEditing
        ? 'Promotion updated successfully'
        : 'Promotion added successfully'

      this.reqBusy = true
      try {
        const res = await this.$http({ method, url, data: formData })

        // emit save event
        this.$emit('save', res.data)

        // Close ES
        this.$edgeStack.close(this.esId)
        // Reset the form
        this.$edgeStack.emitter.on(
          this.$edgeStack.getEventName('closed', this.esId),
          () => {
            this.resetForm()
            // this.$edgeStack.shouldNotConfirm(this.esId)
          }
        )

        this.$edgeStack.shouldNotConfirm(this.esId)

        this.$notify({
          group: 'generic',
          type: 'success',
          title: 'Success',
          text: message,
        })
      } catch (err) {
        const asCustomErrorArray = Object.entries(this.customError)
        const filteredKeyArray = asCustomErrorArray.filter(([key, value]) => {
          console.log(value)
          return Object.entries(err.response.data)[0][0].endsWith(key)
        })
        if (filteredKeyArray.length > 0) {
          this.customError[filteredKeyArray[0][0]] = Object.entries(
            err.response.data
          )[0][1][0]
        } else {
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: err.response.data,
            },
            10000
          )
        }
      } finally {
        this.reqBusy = false
        this.$edgeStack.shouldNotConfirm(this.esId, 'save_clicked')
      }
    },
    getFormModel() {
      return {
        is_active: true,
        title: '',
        code: '',
        start_date: '',
        end_data: '',
        promotion_category: 'RD',
        percentage: '',
        min_travel_time_in_sec: '',
        // usage limit
        daily_used: '',
        // max trips
        num_of_trips: '',
        // max discount
        max_amount: '',
        flat_discount_amount: '',
        assigned_fleets: '',
        description: '',
      }
    },
    resetForm() {
      this.form = { ...this.getFormModel() }
    },
  },
}
</script>

<style lang="scss" scoped>
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.header-status {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
  margin-right: 10px;
}
.toggle-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.promo-type-card {
  width: 100%;
  height: 200px;
  background: white;
  border-radius: 22px;
  border: 3px dashed #ee9200;
  margin-top: 20px;
  position: relative;
  .promo-right-icon {
    width: 200px;
    height: 55px;
    background: #ee9200;
    position: absolute;
    right: -3px;
    top: -3px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 60px;
    display: flex;
    span {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      font-family: Roboto;
    }
  }
  .promo-card-body {
    h3 {
      font-family: Roboto;
      font-size: 37px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #0d0d0d;
    }
    span {
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ee9200;
      margin-top: 5px;
    }
  }
}
.multi-selecter {
  span {
    width: 100%;
  }
}
.multiselect__tags {
  border-color: #cbd5e0 !important;
}
</style>
