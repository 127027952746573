<template>
  <div class="block">
    <div
      :class="
        `promo-type-card flex items-center justify-center ${
          this.type == 'RD' ? rd_color : this.type == 'FD' ? fd_color : fr_color
        }`
      "
    >
      <div
        :class="
          `promo-right-icon items-center justify-center ${
            this.type == 'RD' ? rd_bg : this.type == 'FD' ? fd_bg : fr_bg
          }`
        "
      >
        <span>{{ type == 'FD' ? 'Flat' : title.toUpperCase() }}</span>
      </div>
      <div class="promo-card-body">
        <h3 class="block text-center">
          {{ this.type == 'RD' ? percent : this.type == 'FD' ? flat : '' }}
          {{ title }}
        </h3>
        <span class="block text-center">CODE: {{ code }}</span>
      </div>
      <div class="bottom-right-icon">
        <img
          :src="
            this.type == 'RD'
              ? require('../../assets/img/tag1.png')
              : this.type == 'FD'
              ? require('../../assets/img/tag2.png')
              : require('../../assets/img/tag3.png')
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromoCard',
  components: {},
  data() {
    return {
      isLoading: false,
      rd_color: 'rd_color',
      fd_color: 'fd_color',
      fr_color: 'fr_color',
      rd_bg: 'rd_bg',
      fd_bg: 'fd_bg',
      fr_bg: 'fr_bg',
    }
  },
  props: {
    type: {
      required: false,
    },
    code: {
      required: false,
    },
    percent: {
      required: false,
    },
    flat: {
      required: false,
    },
    title: {
      required: false,
    },
  },
  mounted() {
    console.log('data')
  },
  methods: {
    updateCard: function() {
      console.log(this.type)
      if (this.type == 'RD') this.active_class = 'rd_color'
      else if (this.type == 'FD') this.active_class = 'fd_color'
      else if (this.type == 'FR') this.active_class = 'fr_color'
    },
  },
}
</script>

<style lang="scss" scoped>
.promo-type-card {
  width: 100%;
  height: 100%;
  min-height: 200px;
  background: white;
  border-radius: 22px;
  border: 3px dashed #ee9200;
  position: relative;
  .promo-right-icon {
    width: 180px;
    height: 55px;
    background: #ee9200;
    position: absolute;
    right: -3px;
    top: -3px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 60px;
    display: flex;
    span {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      font-family: Roboto;
    }
  }
  .promo-card-body {
    h3 {
      font-family: Roboto;
      font-size: 37px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #0d0d0d;
    }
    span {
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ee9200;
      margin-top: 5px;
    }
  }
}
.rd_color {
  border-color: #ee9200 !important;
}
.fr_color {
  border-color: #a414f2 !important;
}
.fd_color {
  border-color: #0093ee !important;
}
.rd_bg {
  background: #ee9200 !important;
}
.fr_bg {
  background: #a414f2 !important;
}
.fd_bg {
  background: #0093ee !important;
}
.bottom-right-icon {
  position: absolute;
  /* top: 0px; */
  bottom: -3px;
  right: -3px;
  img {
    width: 100px;
  }
}
</style>
