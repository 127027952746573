<template lang="">
  <div class="mt-3 md:mt-0">
    <t-dropdown variant="smActions">
      <template
        #trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler, 
        }"
      >
        <button
          class="text-oDark focus:outline-none"
          aria-label="Task Status Menu"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <i class="text-sm text-gray-600 fas fa-ellipsis-v" />
        </button>
      </template>

      <template #default="{ hide }">
        <div
          @click="hide()"
          class="py-1 mt-px bg-white rounded-md shadow-md z-200"
        >
          <t-dropdown-item @click="executeAction('notifyRiders')">
            Notify Riders
          </t-dropdown-item>
        </div>
      </template>
    </t-dropdown>
    <NotifyModal
      :id="`notify-${data.id}`"
      :key="`notify-${data.id}`"
      :isLoading="isLoading"
      :title="`Please Select your push medium*`"
      :subtitle="`Please select notification channels (SMS, Push, Email)`"
      :cancelButtonText="`Cancel`"
      :cancelButtonVariant="`secondary`"
      :notify-button-text="`Notify`"
      :notify-button-variant="`green`"
      @setQueryString="(query) => (queryString = query)"
      @notify="actionNotify"
      @cancel="executeAction('hideNotifyModal')"
    />
  </div>
</template>
<script>
import { useEndpoints } from '@/composables'

export default {
  name: 'TaskMoreActionsDropdown',
  components: {
    TDropdownItem: () => import('@/components/dropdown/TDropdownItem'),
    NotifyModal: () => import('@/components/modals/NotifyModal'),
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    queryString: null,
  }),
  methods: {
    async actionNotify() {
      this.isLoading = true
      const url =
        useEndpoints.promo.notifyRiders(this.data.id) + this.queryString

      await this.$http
        .post(url)
        .then((res) => {
          console.log('notifySuccess--', { res })
          this.$modal.hide(`notify-${this.data.id}`)
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Successful',
              text: res.data.message,
            },
            3000
          )
        })
        .catch((err) => {
          console.log('notifyErr--', { err })
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: `Error Occurred [${err.response.status}]`,
              text: err.response.data.message,
            },
            3000
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    executeAction(type) {
      if (this.$acl.canNotUpdate('promotions')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'notify',
            permission: 'this promotions',
          })
        )
        return
      }
      if (type === 'notifyRiders') {
        console.log('promo-data', this.data.status)
        if (['Inactive'].includes(this.data.status)) {
          this.$notify(
            {
              group: 'generic',
              type: 'default',
              title: 'Inactive Promotion!',
              text:
                "You can't notify riders for inactive Promotion. Please activate the Promotion first.",
            },
            5000
          )
          return
        }
        this.$modal.show(`notify-${this.data.id}`)
      }
      if (type === 'hideNotifyModal') {
        this.$modal.hide(`notify-${this.data.id}`)
      }
    },
  },
}
</script>
<style lang=""></style>
