var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EdgeStack',{staticClass:"relative",attrs:{"id":_vm.esId,"header-class-name":"mb-4 mt-8 px-4"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"items-center panel-title header-title"},[_vm._v(" "+_vm._s(_vm.getTitleText)+" ")])])]},proxy:true},{key:"footer",fn:function(){return [_c('t-button',{staticClass:"ml-3",attrs:{"type":"submit"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Save")])]},proxy:true}])},[_c('loading',{attrs:{"active":_vm.reqBusy,"is-full-page":false},on:{"update:active":function($event){_vm.reqBusy=$event}}}),_c('div',{staticClass:"px-6 pt-8"},[_c('PromoCard',{ref:"promoCard",attrs:{"title":_vm.getPromoTitle,"type":_vm.getPromoCategory,"code":_vm.getPromoCode,"percent":_vm.getPromoValue,"flat":_vm.getPromoValue}}),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('div',{staticClass:" mt-8"},[_c('AppInput',{attrs:{"type":"richselect","name":"Promo Type","label":"Promo Type","placeholder":"Select a Promo type","options":_vm.getPromoCategoryOptions,"hide-search-box":"","rules":"required"},model:{value:(_vm.form.promotion_category),callback:function ($$v) {_vm.$set(_vm.form, "promotion_category", $$v)},expression:"form.promotion_category"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-2"},[_c('text-input',{attrs:{"rules":"required","label":"Promo Name","placeholder":"e.g. Bijoy","errorMessage":_vm.customError.title,"errorKey":"title"},on:{"clear-error":function ($event) {
                _vm.customError[$event] = ''
              }},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('text-input',{attrs:{"value":_vm.form.code.toUpperCase(),"rules":"required","label":"Promo Code","placeholder":"e.g. BIJOY16","errorMessage":_vm.customError.code,"errorKey":"code"},on:{"input":function ($event) {
                _vm.form.code = $event.toUpperCase()
              },"clear-error":function ($event) {
                _vm.customError[$event] = ''
              }}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-2"},[_c('text-input',{attrs:{"rules":"required","label":"Promo Start Date","type":"date","min":_vm.minStartDate},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}}),_c('text-input',{attrs:{"rules":"required","label":"Promo Expire Date","type":"date","min":_vm.minEndDate},model:{value:(_vm.form.end_date),callback:function ($$v) {_vm.$set(_vm.form, "end_date", $$v)},expression:"form.end_date"}})],1),_c('div',{staticClass:"w-full mt-2"},[_c('text-input',{attrs:{"value":_vm.getPromoValidityDuration,"label":"Usage validity","placeholder":"days","disabled":""}})],1),_c('div',{staticClass:"mt-2"},[_c('ValidationProvider',{attrs:{"name":"Fleet Currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"text":"Fleet Currency *","error":errors[0]}}),_c('TRichSelect',{attrs:{"options":_vm.getFleetCurrencyOptions,"variant":{ danger: !!errors[0] },"placeholder":"Select fleet currency"},model:{value:(_vm.fleetCurrencyProxy),callback:function ($$v) {_vm.fleetCurrencyProxy=$$v},expression:"fleetCurrencyProxy"}}),(errors[0])?_c('input-error-item',{attrs:{"message":errors[0]}}):_vm._e()]}}],null,true)}),_c('div',{staticClass:"mt-1 text-xs text-gray-600"},[_vm._v(" You have total "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.fleetList.length || 0))]),_vm._v(" fleets with "),_c('span',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm.getFleetCurrencyOptions.length || 0)+" ")]),_vm._v(" unique "+_vm._s(_vm.getFleetCurrencyOptions.length > 1 ? 'currencies' : 'currency')+". You can let your users use the promo code on single or multiple fleets of same currency. ")])],1),_c('div',{staticClass:"mt-4"},[_c('ValidationProvider',{attrs:{"name":"Assigned Fleet","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":"Assigned Fleet *"}}),_c('TRichSelect',{attrs:{"options":_vm.getFleetOptions,"close-on-select":false,"variant":{ danger: !!errors[0] },"placeholder":_vm.fleetCurrencyProxy
                  ? 'Select fleet(s)'
                  : 'Select a fleet currency first',"multiple":""},model:{value:(_vm.form.assigned_fleets),callback:function ($$v) {_vm.$set(_vm.form, "assigned_fleets", $$v)},expression:"form.assigned_fleets"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1),_c('div',{staticClass:"mt-4"},[_c('ValidationProvider',{attrs:{"name":"Target Rider Group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":"Target Rider Group *"}}),_c('TRichSelect',{attrs:{"options":_vm.targetGroupOptions,"close-on-select":false,"variant":{ danger: !!errors[0] },"placeholder":"Select Target Group","multiple":""},model:{value:(_vm.form.eligible_point_categories),callback:function ($$v) {_vm.$set(_vm.form, "eligible_point_categories", $$v)},expression:"form.eligible_point_categories"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1),(_vm.getPromoCategory != 'FR')?_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-2"},[(_vm.getPromoCategory == 'RD')?[_c('text-input',{attrs:{"type":"number","rules":"required","label":"Discount Parcentage (%)","placeholder":"e.g. 15%","errorMessage":_vm.customError.percentage,"errorKey":"percentage","subText":"Enter valid positive number.","showSubText":true},on:{"clear-error":function ($event) {
                  _vm.customError[$event] = ''
                }},model:{value:(_vm.form.percentage),callback:function ($$v) {_vm.$set(_vm.form, "percentage", $$v)},expression:"form.percentage"}})]:(_vm.getPromoCategory == 'FD')?[_c('text-input',{attrs:{"type":"number","rules":"required","label":"Flat Discount Amount","placeholder":"e.g. $20","errorMessage":_vm.customError.flat_discount_amount,"errorKey":"flat_discount_amount","subText":"Enter valid positive number.","showSubText":true},on:{"clear-error":function ($event) {
                  _vm.customError[$event] = ''
                }},model:{value:(_vm.form.flat_discount_amount),callback:function ($$v) {_vm.$set(_vm.form, "flat_discount_amount", $$v)},expression:"form.flat_discount_amount"}})]:_vm._e(),_c('text-input',{attrs:{"rules":"required","label":"Minimum Trip Time (In Mins)","placeholder":"e.g. 30 mins","type":"number","errorMessage":_vm.customError.min_travel_time_in_sec,"errorKey":"min_travel_time_in_sec","subText":"Enter valid positive number.","showSubText":true},on:{"clear-error":function ($event) {
                _vm.customError[$event] = ''
              }},model:{value:(_vm.form.min_travel_time_in_sec),callback:function ($$v) {_vm.$set(_vm.form, "min_travel_time_in_sec", _vm._n($$v))},expression:"form.min_travel_time_in_sec"}})],2):_vm._e(),_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-2"},[_c('text-input',{attrs:{"rules":"required","label":"Max Number of Trips","placeholder":"e.g. 10","type":"number","errorMessage":_vm.customError.num_of_trips,"errorKey":"num_of_trips","subText":"Enter valid positive number.","showSubText":true},on:{"clear-error":function ($event) {
                _vm.customError[$event] = ''
              }},model:{value:(_vm.form.num_of_trips),callback:function ($$v) {_vm.$set(_vm.form, "num_of_trips", $$v)},expression:"form.num_of_trips"}}),_c('text-input',{attrs:{"rules":"required","label":"Daily Used","placeholder":"e.g. 2","type":"number","errorMessage":_vm.customError.daily_used,"errorKey":"daily_used","subText":"Enter valid positive number.","showSubText":true},on:{"clear-error":function ($event) {
                _vm.customError[$event] = ''
              }},model:{value:(_vm.form.daily_used),callback:function ($$v) {_vm.$set(_vm.form, "daily_used", $$v)},expression:"form.daily_used"}})],1),(_vm.getPromoCategory == 'RD')?_c('div',{staticClass:"w-full"},[_c('text-input',{attrs:{"rules":"required","label":"Max Discounted Trip Cost","placeholder":"e.g. $5","type":"number","errorMessage":_vm.customError.max_amount,"errorKey":"max_amount","subText":"Enter valid positive number.","showSubText":true},on:{"clear-error":function ($event) {
                _vm.customError[$event] = ''
              }},model:{value:(_vm.form.max_amount),callback:function ($$v) {_vm.$set(_vm.form, "max_amount", $$v)},expression:"form.max_amount"}})],1):_vm._e(),_c('div',{staticClass:"mt-2"},[_c('input-label',{attrs:{"text":"Status"}}),_c('t-rich-select',{staticClass:"w-full",attrs:{"placeholder":"Select status","options":_vm.statusOptions,"valueAttribute":"value","textAttribute":"text","hideSearchBox":true},model:{value:(_vm.form.is_active),callback:function ($$v) {_vm.$set(_vm.form, "is_active", $$v)},expression:"form.is_active"}})],1),_c('div',{staticClass:"mt-5"},[_c('ValidationProvider',{attrs:{"name":"Promo description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":"Promo description"}}),_c('t-textarea',{attrs:{"rules":""},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v("Save")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }