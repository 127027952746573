export const PromoConfig = {
  api: {
    // get
    index: '/dashboard/promotions/',
    // post
    create: '/dashboard/promotions/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/promotions/${id}/`
    },
    detail: (id = 'uuid') => {
      return `/dashboard/promotions/${id}/`
    },
    getUsers: (id = 'uuid') => {
      return `/dashboard/promotions/${id}/user-promotions/`
    },
  },
  events: {
    name: 'promos',
    // refresh-index-data
    refresh: `rid-promo`,
    // slideover-right
    sorId: 'promo',
    sorOpen: 'sor-open-promo',
    sorClose: 'sor-close-promo',
    sorToggle: 'sor-toggle-promo',
    // editing-data
    editingData: 'edit-promo-data',
    // viewing-data
    viewingData: 'view-promo-data',
  },
}
